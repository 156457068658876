import { render, staticRenderFns } from "./Verify6.vue?vue&type=template&id=406f1f42&scoped=true&"
import script from "./Verify6.vue?vue&type=script&lang=js&"
export * from "./Verify6.vue?vue&type=script&lang=js&"
import style0 from "./Verify6.vue?vue&type=style&index=0&id=406f1f42&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "406f1f42",
  null
  
)

export default component.exports