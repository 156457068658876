<template>
  <div>
    <EditorFrame
      v-if="hb"
      :hb="hb"
      :audioInfo="audioInfo"
      :showTags="true"
      :showPreview="true"
      :locked="onlyRead||justPreview4rewrite"
    >
      <div v-if="/Intensive/.test(hb.contentType)">
        <IntensiveEditor :hb="hb" :contentLocked="true" />
      </div>
      <HbPreview v-else :hb="hb" />

      <template v-slot:buttons>
        <template v-if="!onlyRead">
          <template v-if="!justPreview4rewrite">
            <Button
              class="center-block-btn"
              type="primary"
              @click="postFinalCheck(0)"
              :disabled="isDisable"
            >确认无误，通过审核</Button>

            <Button
              v-if="!/Teaching/.test(hb.contentType)"
              class="center-block-btn"
              @click="postFinalCheck(2)"
              type="warning"
              :disabled="isDisable"
            >配图有问题，不过审</Button>
            <Button
              class="center-block-btn"
              @click="postFinalCheck(4)"
              type="warning"
              :disabled="isDisable"
            >配音有问题，不过审</Button>
            <Button
              class="center-block-btn"
              @click="postFinalCheck(3)"
              type="warning"
              v-if="/^en/.test(hb.contentType)"
              :disabled="isDisable"
            >翻译有问题，不过审</Button>

            <DeleteHbButton :hb="hb" class="center-block-btn" :disabled="isDisable" />
          </template>
          <template v-else>
            <Button
              @click="makesureRewrite"
              class="center-block-btn"
              type="primary"
              v-if="!hb.rewriteName"
            >确认重写</Button>
            <Button
              @click="toRewrite"
              class="center-block-btn"
              type="primary"
              v-else-if="self===hb.rewriteName"
            >继续重写</Button>
            <Button class="center-block-btn" v-else disabled>
              正在被
              <b>{{hb.rewriteName}}</b> 重写
            </Button>
          </template>
        </template>
      </template>

      <template v-slot:right-side>
        <Tabs v-if="onlyRead">
          <TabPane label="当前配音" name="name3">
            <AudioInfo :hb="hb" @audioText="playAudio" />
          </TabPane>
        </Tabs>
        <Tabs v-else :value="'name2'">
          <TabPane label="编辑状态" name="name2">
            <EditStatus :hb="hb" />
          </TabPane>

          <TabPane label="当前配音" name="name3">
            <AudioInfo :hb="hb" @audioText="playAudio" />
          </TabPane>
          <TabPane label="当前翻译" name="name4" v-if="/^en/.test(hb.contentType)">
            <TranslateEditor :editAble="false" :transList="hb.audioMap[currentIndex]" />
          </TabPane>

          <TabPane label="版本记录" name="name1" v-if="!justPreview4rewrite">
            <colophon :taskInfo="taskInfo"></colophon>
          </TabPane>
        </Tabs>
      </template>
    </EditorFrame>
  </div>
</template>

<script >
import axios from "../api/newAxiosProxy";
import { getHbTaskView } from "../api/hbService";
import { getRewriteContent } from "../api/startTaskService";
import editorMixin from "./editorMixin";
export default {
  data() {
    return {
      hb: null,
      taskInfo: {},
      isDisable: false, // 开关
      audioInfo: null,
      justPreview4rewrite: false
    };
  },
  computed: {
    self() {
      return this.$store.state.userInfo.realname; // username , realname
    },
    onlyRead() {
      // onlyRead用户只能看和听，什么都干不了(业务逻辑优先级最高)
      return this.$route.query.onlyRead === "true";
    }
  },
  methods: {
    playAudio(info) {
      this.audioInfo = info;
    },
    //获取任务详情
    initTaskView() {
      let { taskId, processId, bizId, contentType } = this.$route.query;
      let params = { taskId, processId, bizId, contentType };
      this.$Spin.show();

      getHbTaskView(params, () => {
        this.$Spin.hide();
      }).then(res => {
        this.hb = res.content;
        this.hb.taskName = res.taskName;
        this.taskInfo = res.taskInfo;
      });
    },
    initTaskPreview() {
      let { contentType, rewriteId: id } = this.$route.query;
      this.$Spin.show();
      getRewriteContent({
        contentType,
        id,
        taskName: this.$store.getters.getRoleCode
      })
        .then(res => {
          this.hb = res;
        })
        .finally(() => {
          this.$Spin.hide();
        });
    },
    makesureRewrite() {
      this.$Spin.show();
      axios({
        method: "post",
        url: "/contentExt/rewriteContent",
        data: {
          contentType: this.hb.contentType,
          parentId: this.$route.query.rewriteId,
          parentProcessId: this.$route.query.parentProcessId,
          taskName: this.$store.getters.getRoleCode
        }
      })
        .then(() => {
          this.toRewrite();
        })
        .catch(err => {
          this.$Message.warning(err);
        })
        .finally(() => {
          this.$Spin.hide();
        });
    },
    toRewrite() {
      this.$router.replace(JSON.parse(this.$route.query.desRoute4rewrite));
    },
    //6审最终审发布
    async postFinalCheck(e) {
      let hb = this.hb;
      hb.pass = e;
      this.isDisable = true;

      let msg = this.$Message.loading({
        content: "提交中....",
        duration: 0
      });
      let url = "/common/content/finalCheck";

      axios(
        {
          method: "post",
          url,
          data: hb
        },
        () => {
          msg();
          this.isDisable = false;
        }
      )
        .then(() => {
          this.$Message.success("提交成功");
          this.isDropTask = false;
          setTimeout(() => {
            this.$router.back();
          }, 500);
        })
        .finally(() => {
          msg();
          this.isDisable = false;
        });
    }
  },
  mounted() {
    this.currentIndex = 0;
    if (this.$route.query.rewriteId) {
      this.justPreview4rewrite = true;
      this.initTaskPreview();
    } else {
      this.initTaskView();
    }
  },
  mixins: [editorMixin]
};
</script>

<style scoped lang="scss">
::v-deep .ivu-tabs {
  width: 600px;
}
.up_tip {
  margin-bottom: 20px;
}

.typeSelect {
  width: 200px;
  margin: 20px;
}
</style>
